<template>
    <div class="page" id="reorganize">
        <el-form
            @keyup.enter.native="getDataList(1,1)"
            class="query-form" :inline="true" size="small" ref="inputForm" :model="inputForm" label-width="100px">
            <el-form-item label="所属类型" prop="thematicType">
                <el-select v-model="inputForm.thematicType"
                           placeholder="请选择所属类型"
                           style="width: 100%"
                           clearable>
                    <el-option
                            v-for="item in this.$dictUtils.getDictList('thematic_type')"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="发布申请人" prop="createUser">
                <el-select
                        style="width: 100%;"
                        v-model="inputForm.createUser"
                        filterable
                        remote
                        clearable
                        reserve-keyword
                        placeholder="请选择发布申请人"
                        @focus="remoteMethod()"
                        :remote-method="remoteMethod"
                        :loading="personnelLoading">
                    <el-option
                            v-for="item in personnelList"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="专题库名称" prop="thematicName">
                <el-input v-model.trim="inputForm.thematicName" clearable maxlength="20"
                          placeholder="请输入专题库名称(限20字)"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" icon="el-icon-search" @click="getDataList(1,1)" size="small">查询</el-button>
                <el-button @click="resetting()" size="small" icon="el-icon-refresh-right">重置</el-button>
            </el-form-item>
        </el-form>
        <div class="bg-white">
            <div class="tabsCls">
                <el-tabs size="small" v-model="inputForm.processingState" @tab-click="handleClick">
                    <el-tab-pane v-for="item in tabsList" :key="item.value" :label="item.name"
                                 :name="item.value"></el-tab-pane>
                </el-tabs>
            </div>
            <el-table
                    :data="dataList"
                    size="small"
                    :row-key="'id'"
                    @selection-change="selectionChangeHandle"
                    v-loading="loading"
                    ref="multipleTable"
                    height="calc(100vh - 350px)"
                    class="table"
            >
                <el-table-column prop="thematicName" label="专题库名称" show-overflow-tooltip width="80px"></el-table-column>
                <el-table-column prop="description" label="专题库建设描述" show-overflow-tooltip min-width="110px">
                  <template slot="header" slot-scope="scope">
                    <el-tooltip class="item" effect="dark" content="专题库建设描述" placement="top-start">
                      <span class="omit">专题库建设描述</span>
                    </el-tooltip>
                  </template>
                </el-table-column>
                <el-table-column prop="thematicType" label="专题库所属类型" show-overflow-tooltip min-width="110px">
                  <template slot="header" slot-scope="scope">
                    <el-tooltip class="item" effect="dark" content="专题库所属类型" placement="top-start">
                      <span class="omit">专题库所属类型</span>
                    </el-tooltip>
                  </template>
                    <template slot-scope="scope">
                        {{ $dictUtils.getDictLabel("thematic_type", scope.row.thematicType, '-') }}
                    </template>
                </el-table-column>
                <el-table-column prop="ownership" label="专题库归属" show-overflow-tooltip>
                    <template slot-scope="scope">
                        {{ scope.row.ownership == 0 ? '全馆' : scope.row.ownership == 1 ? '部门选择' : '个人选择' }}
                    </template>
                </el-table-column>
                <el-table-column prop="resourceNum" label="资源数量" show-overflow-tooltip></el-table-column>
                <el-table-column prop="createUserName" label="申请人" show-overflow-tooltip></el-table-column>
                <el-table-column prop="createDate" label="申请时间" show-overflow-tooltip></el-table-column>
                <el-table-column prop="publishState" label="公开状态" show-overflow-tooltip>
                    <template slot-scope="scope">
                        {{ $dictUtils.getDictLabel("publish_state", scope.row.publishState, '-') }}
                    </template>
                </el-table-column>
                <el-table-column prop="updateUserName" label="审核人" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <span v-if="scope.row.publishState == '2' && scope.row.updateUser == null">系统</span>
                        <span v-else>{{scope.row.updateUserName}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="updateDate" label="审核时间" show-overflow-tooltip></el-table-column>
                <el-table-column fixed="right" label="操作" width="180">
                    <template slot-scope="scope">
                        <el-button size="mini" type="text"
                                   @click="resource(scope.row,scope.$index,2)"
                                   v-if="inputForm.processingState == 0"
                                   v-show="hasPermissionButton(`dyyg:manage:releaseReview:handle`)">处理
                        </el-button>
                        <el-button size="mini" type="text"
                                   @click="resource(scope.row,scope.$index,3)"
                                   v-if="inputForm.processingState == 1"
                                   v-show="hasPermissionButton(`dyyg:manage:releaseReview:see`)">详情
                        </el-button>
                        <el-button type="text" size="small"
                                   v-if="hasPermission('resourceAuditList:auditLog')"
                                   @click="auditLog(scope.row)">审核记录
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div class="text_center">
                <el-pagination
                        @size-change="sizeChangeHandle"
                        @current-change="currentChangeHandle"
                        :current-page="current"
                        :page-sizes="[10, 20, 50, 100]"
                        :page-size="size"
                        :total="total"
                        background
                        layout="total, sizes, prev, pager, next, jumper"
                >
                </el-pagination>
            </div>
        </div>
        <coll-list ref="collList" @refresh="getDataList()"></coll-list>
        <AuditLog ref="auditLog"></AuditLog>
    </div>
</template>

<script>
import CollList from "@/views/modules/dyyg/libraryManagement/collList.vue";
import AuditLog from './auditLog'
export default {
    name: "releaseReview",
    components: {CollList,AuditLog},
    data() {
        return {
            selectTab: '0',
            tabsList: [
                {
                    name: '待处理',
                    value: '0',
                },
                {
                    name: '已处理',
                    value: '1',
                },
            ],
            inputForm: {
                thematicType: '',
                createUser: '',
                thematicName: '',
                processingState: "",
            },
            personnelLoading: false,
            personnelList: [],

            loading: false,
            dataList: [],
            dataListSelect: [],
            current: 1,
            size: 10,
            total: 0,
        }
    },

    mounted() {
        let homeSetOpt = JSON.parse(sessionStorage.getItem('homeSetOpt'))
        if (homeSetOpt) {
            this.$refs.collList.init(2, '', '', homeSetOpt)
            sessionStorage.removeItem('homeSetOpt')
        }
        this.getDataList()
    },

    methods: {
        getDataList(type, dividePage) {
            if (type == 1) {
                this.current = 1
            }
            if (dividePage) {
                this.$refs.multipleTable.clearSelection()
            }
            this.loading = true
            this.$axios(this.api.dyyg.thematiclibrarypublishList, {
                current: this.current,
                size: this.size,
                ...this.inputForm,
            }, 'get').then((res) => {
                if (res.status) {
                    this.dataList = res.data.records
                    this.total = parseInt(res.data.total)
                    this.$nextTick(() => {
                        this.$refs.multipleTable.doLayout()
                    })
                    if (this.dataList.length == 0 && this.current > 1) {
                        this.current--
                        this.getDataList()
                    }
                } else {
                    this.$message.error('查询失败');
                }
                this.loading = false
            })
        },

        //重置
        resetting() {
            this.$refs.inputForm.resetFields();
            this.getDataList(1, 1)
        },

        //处理
        resource(row, index, num) {
            let searchData = {
                current: this.current,
                size: this.size,
                ...this.inputForm,
            }
            let searchRecord = {
                current: (this.current - 1) * this.size + index + 1,
                size: 1,
                ...this.inputForm
            }
            sessionStorage.setItem('registrationSearch', JSON.stringify(searchRecord))
            this.$refs.collList.init(num, row, searchData)
        },

        //表格勾选数据
        selectionChangeHandle(val) {
            this.dataListSelect = val
        },

        // 展览每页数
        sizeChangeHandle(val) {
            this.size = val;
            this.getDataList(1, 1)
        },
        // 展览当前页
        currentChangeHandle(val) {
            this.current = val;
            this.getDataList('', '');
        },

        handleClick(tab, event) {
            this.getDataList(1, 1)
        },
        // 审核记录
        auditLog(row) {
            this.$refs.auditLog.init(row.id, null,22)
        },
        //边打边搜
        remoteMethod(query) {
            this.personnelLoading = true;
            this.$axios(this.api.collection.searchUserByLike, {username: query || ''}, 'get').then(data => {
                if (data.status) {
                    this.personnelList = data.data
                    this.personnelLoading = false;
                }
            })
        },
    },
}
</script>

<style scoped>
.bg-white {
    overflow-y: auto !important;
}

/*去掉tabs底部的下划线*/
.tabsCls >>> .el-tabs__nav-wrap::after {
    position: static !important;
}
</style>
